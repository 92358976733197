import { useEffect, useState } from 'react'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
// Types
import { CartShippingAddress, CartShippingMethod } from '../types/CartTypes'
// Utils
import {
  setBillingAndShippingAddressOnCart,
  setEventBillingAndShippingOnCart,
} from '../utils/addressHelpers'

const initialShippingState = {
  isShippingMethodSet: false,
  // isShippingSet: false,
  shippingAddress: null,
  shippingMethod: null,
}

interface InitShippingState {
  isShippingMethodSet: boolean
  // isShippingSet: boolean
  shippingAddress: CartShippingAddress
  shippingMethod: CartShippingMethod
}

const useSetShipping = (shouldSkip: boolean) => {
  const [
    { shippingAddress, shippingMethod, isShippingMethodSet },
    setCartShippingState,
  ] = useState<InitShippingState>(initialShippingState)

  const handleSetCartShippingState = (
    shippingState: Partial<InitShippingState>
  ) =>
    setCartShippingState(prev => ({
      ...prev,
      ...shippingState,
    }))

  const { isEventSite, isVipSite, magentoUser } = useAuthContext()
  const { cartData, cartId, buildCart, isShippingSet, handleSetCartState } =
    useCartContext()
  const { localeCountryCode } = usePrismic()

  const findEventAddress = cartData?.shipping_addresses?.find(
    address => address?.telephone === '3854682222'
  )

  const handleEventSiteAddress = async () => {
    await setEventBillingAndShippingOnCart(
      cartId,
      magentoUser?.firstname,
      magentoUser?.lastname,
      buildCart
    )
    handleSetCartState({
      isShippingSet: true,
    })
    // if (findEventAddress) {
    //   handleSetCartShippingState({
    //     isShippingSet: true,
    //     shippingAddress: findEventAddress,
    //   })
    // } else {
    //   await setEventBillingAndShippingOnCart(
    //     cartId,
    //     magentoUser?.firstname,
    //     magentoUser?.lastname,
    //     buildCart
    //   )
    // }
  }

  // save shipping & billing addresses on cart & state
  useEffect(() => {
    if (!cartData?.email || !magentoUser) return
    if (shouldSkip) return

    if (isEventSite || isVipSite) {
      if (isShippingSet && !!findEventAddress) return
      handleEventSiteAddress()
      return
    }

    // filter both addresses to ensure country code matches that of user locale
    const cartAddresses = cartData?.shipping_addresses.filter(
      address =>
        address.country.code.toUpperCase() === localeCountryCode.toUpperCase()
    )
    const userAddresses = magentoUser?.addresses.filter(
      address =>
        address.country_code.toUpperCase() === localeCountryCode.toUpperCase()
    )
    // if cart shipping address already exists, set state
    if (cartAddresses?.length) {
      handleSetCartShippingState({
        // isShippingSet: true,
        shippingAddress: cartAddresses[0],
      })
      handleSetCartState({
        isShippingSet: true,
      })
      return
    }

    // if no cart addresses, find user default address and set
    const findDefaultUserAddress = addresses =>
      addresses?.find(address => address?.default_shipping)

    const defaultAddress = findDefaultUserAddress(userAddresses)

    if (defaultAddress) {
      setBillingAndShippingAddressOnCart(
        cartId,
        defaultAddress,
        buildCart,
        true
      )

      handleSetCartShippingState({
        // isShippingSet: true,
        shippingAddress: defaultAddress,
      })
      handleSetCartState({
        isShippingSet: true,
      })
      return
    }
  }, [cartData?.shipping_addresses, magentoUser])

  // save shipping method to state
  useEffect(() => {
    if (!isShippingSet) return

    const cartAddresses = cartData?.shipping_addresses
    if (!cartAddresses?.length) return

    const methodAvailable = cartAddresses[0]?.available_shipping_methods
    if (methodAvailable) {
      handleSetCartShippingState({
        shippingMethod: methodAvailable[0],
        isShippingMethodSet: true,
      })
    }
  }, [shippingAddress])

  return { shippingAddress, shippingMethod }
}

export default useSetShipping
