import React from 'react'
// Context
// import { usePrismic } from '../../context/PrismicContext'
// Styles
import { SavedWrapper } from './OrderDetails.styles'

const TotalSavings = ({ savings, isPcMessage }) => {
  // const {
  //   prismicData: { prismicCartPage },
  // } = usePrismic()

  // const { you_save_on_your_order } = prismicCartPage

  const savingsText = `You save {savedAmount} on your order by being a Q Sciences ${
    isPcMessage ? 'Preferred Customer' : 'Ambassador'
  }`
  // const initialText = you_save_on_your_order[0].text
  const replacedHTML = savingsText.replace(
    '{savedAmount}',
    `<span>$${savings.toFixed(2)}</span>`
  )

  if (!savings) {
    return null
  }

  return (
    <>
      <SavedWrapper
        data-qa="saved"
        dangerouslySetInnerHTML={{ __html: replacedHTML }}
      />
    </>
  )
}

export default TotalSavings
